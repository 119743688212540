import api from "../utils/api";
class DefectsService {


    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {Object} Object of query params
    * @returns
    */
    getDefects(abortController, params = null) {
        const config = {
            url: '/defects',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }


    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {String} id of query params
    * @returns
    */
    getDefect(abortController, params = null) {
        const config = {
            url: '/defects',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }

       /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {String} id of query params
    * @returns
    */
       getDefectStatus(abortController, params = null) {
        const config = {
            url: '/defects/statuses',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }

        /**
     *
     * @param {string} id id of role to update
     * @param {Object} data Object of test suite data
     * @returns
     */
        updateStatus(id, data) {
            console.log(data , "____data status____")
            const config = { url: `/defects/${id}/status/update`, method: 'post', data }
            return api.request(config)
        }

    /**
     *
     * @param {object} data Object of test plan data
     * @returns
     */
    createDefect(data) {
        const config = { url: '/defects', method: 'post', data }
        return api.request(config)
    }


}
const defectService = new DefectsService()
export default defectService
