// import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import React, { useState, useEffect, useRef, Suspense, useCallback } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';



// import ButtonDemo from './components/ButtonDemo';
// import ChartDemo from './components/ChartDemo';
// import Documentation from './components/Documentation';
// import FileDemo from './components/FileDemo';
// import FloatLabelDemo from './components/FloatLabelDemo';
// import FormLayoutDemo from './components/FormLayoutDemo';
// import InputDemo from './components/InputDemo';
// import ListDemo from './components/ListDemo';
// import MenuDemo from './components/MenuDemo';
// import MessagesDemo from './components/MessagesDemo';
// import MiscDemo from './components/MiscDemo';
// import OverlayDemo from './components/OverlayDemo';
// import MediaDemo from './components/MediaDemo';
// import PanelDemo from './components/PanelDemo';
// import TableDemo from './components/TableDemo';
// import TreeDemo from './components/TreeDemo';
// import InvalidStateDemo from './components/InvalidStateDemo';
// import BlocksDemo from './components/BlocksDemo';
// import IconsDemo from './components/IconsDemo';


// import Crud from './pages/Crud';
// import EmptyPage from './pages/EmptyPage';
// import TimelineDemo from './pages/TimelineDemo';
import ProjectList from './Modules/Project/ProjectList';
// import Sidebar from './components/sidebar/Sidebar';

import { Ripple } from 'primereact/ripple';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import { Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';
import secureLocalStorage from './utils/secureLocalStorage';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';

// import RoleAdd from './Modules/Roles/RoleAdd';
// import Folders from './Modules/Folders/Folders';
// import UsersList from './Modules/Users/UsersList'
import { ProgressSpinner } from 'primereact/progressspinner';
import Suite from './Modules/Folders/Testsuite/Suite';
import Spinner from './components/common/Spinner/Spinner';
import TestPlanList from './Modules/TestPlan/TestPlanList';
import TestPlanDetails from './Modules/TestPlan/TestPlanDetails/TestPlanDetails';
import TestPlanLogs from './Modules/TestPlan/Logs/TestPlanLogs';
// import TestPlanLogsItems from './Modules/TestPlan/Logs/TestPlanLogsItems';
import CustomVariables from './Modules/CustomVariables/CustomVariable'
import Defects from './Modules/Defects';
import ConfigVariables from './Modules/ConfigVariables';
import TestConfiguration from './Modules/TestConfiguration';
import { ComponentIcon, ConfigurationIcon, DashboardIcon, DefectsIcon, LogsIcon, ProjectsIcon, RolesIcon, TestConfigurationIcon, TestIcon, TestPlanIcon, TicketingIcon, UserIcon, VariablesIcon } from './utils/icons';
// const RequireAuth = ({ children }) => {
//     const dispatch = useDispatch()
//     const auth = secureLocalStorage.getItem('auth')

//     dispatch({type:'SET_AUTH_INFO',auth})
//     console.log(!auth.token)
//     if (!auth.token) {
//         console.log('unauthiorz')
//         // return null
//           return <Navigate to="/login" />;
//     }
//     return children;
// }

const Login = React.lazy(() => import('./components/Login/Login'));
const ResetPassword = React.lazy(() => import('./components/Login/ResetPassword'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const RoleAdd = React.lazy(() => import('./Modules/Roles/RoleAdd'));
const Folders = React.lazy(() => import('./Modules/Folders/Folders'));
const UsersList = React.lazy(() => import('./Modules/Users/UsersList'));
const ComponentAdd = React.lazy(() => import('./Modules/Components/ComponentAdd'));
const ComponentsList = React.lazy(() => import('./Modules/Components/ComponentsList'));
const TicketList = React.lazy(() => import('./Modules/Ticketing/TicketList'));
// const CustomVariables=React.lazy(() => import('./Modules/CustomVariables/CustomVariable'));
// const Suite =React.lazy(()=>'./Modules/Folders/Testsuite/Suite');
// const Spinner =React.lazy(()=>'./components/common/Spinner/Spinner') ;
// const TestPlanList =React.lazy(()=>'./Modules/TestPlan/TestPlanList');
// const TestPlanDetails =React.lazy(()=>'./Modules/TestPlan/TestPlanDetails/TestPlanDetails') ;
// const TestPlanLogs =React.lazy(()=>'./Modules/TestPlan/Logs/TestPlanLogs'); 
// const TestPlanLogsItems =React.lazy(()=>'./Modules/TestPlan/Logs/TestPlanLogsItems');


// import RoleAdd from './Modules/Roles/RoleAdd';
// import Folders from './Modules/Folders/Folders';
// import UsersList from './Modules/Users/UsersList'
const App = () => {
    const { isLoggedIn } = useSelector(state => state.auth.info)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const copyTooltipRef = useRef();
    const location = useLocation();

    useEffect(() => {
        const currentUser = secureLocalStorage.getItem('currentUser');
      
        if (currentUser.token) {
          if (location.pathname === '/login') {
            navigate('/');
          }
          dispatch({ type: 'SET_AUTH_INFO', data: { ...currentUser, isLoggedIn: true } });
        } else {
          // Exclude redirection for /reset-password 
          if (location.pathname !== '/reset-password') {
            navigate('login');
          }
        }
      }, [navigate, location.pathname, dispatch]);

    // const dum={
    //     username:'faizan',
    //     firstname:'faizan',
    //     lastname:'ahmed',
    //     email:'faizan@qa.com',
    //     token:'auwoieu82374892374092830'
    // }
    // secureLocalStorage.setItem('auth',dum)




    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false, // default: true
            },
        },
    })
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        console.log("onRipple", e)
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }
        if (event)
            event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }
    const allowedMenu = useCallback((menu) => {

        let { user_permissions: permissions } = secureLocalStorage.getItem('currentUser')

        if (permissions) {

            permissions = permissions.reduce((prev, acc) => [...acc.permissions, ...prev], [])
            if (permissions.includes('Has Client')) {
                return menu
            }
            menu[0].items = menu[0].items.filter((nav) => nav.permissions.some(item => permissions.includes(item)))

        }
        return menu
    }, [])
    const allMenus = [

        {
            label: 'Home',
            items: [
                {
                    label: 'Dashboard', icon: <DashboardIcon size={21} />, to: '/',
                    permissions: []
                },
                {
                    label: 'Projects', icon: <ProjectsIcon size={21} />, to: '/projects',
                    permissions: ["Delete Project", "Update Project", "Read Project", "Create Project"]
                },
                {
                    label: 'Test', icon: <TestIcon size={21}/>, to: '/folders',
                    permissions: ["Update Folder", "Delete Folder", "Read Folder", "Create Folder"]
                },
                {
                    label: 'Test Plans', icon: <TestPlanIcon size={21}/>, to: '/test-plan',
                    permissions: ["Update Folder", "Delete Folder", "Read Folder", "Create Folder"]
                },
                {
                    label: 'Test Plans Logs', icon: <LogsIcon size={21}/>, to: '/test-plan-logs',
                    permissions: ["Update Folder", "Delete Folder", "Read Folder", "Create Folder"]
                },
                {
                    label: 'Roles', icon: <RolesIcon size={21}/>, to: '/roles',
                    permissions: ["Create Role", "Read Role", "Update Role", "Delete Role"]
                },
                {
                    label: 'Users', icon: <UserIcon size={21}/>, to: '/users',
                    permissions: ["Create User", "Delete User", "Update User", "Read User"]
                },
                {
                    label: 'Components', icon: <ComponentIcon size={21}/>, to: '/components',
                    permissions: ["Delete Component", "Update Component", "Read Component", "Create Component"]
                },
                {
                    label: 'Custom Variables', icon: <VariablesIcon size={21}/>, to: '/custom-variables',
                    permissions: ["Delete Component", "Update Component", "Read Component", "Create Component"]
                },
                {
                    label: 'Ticketing', icon: <TicketingIcon size={24}/>, to: '/ticketing',
                    permissions: ["Delete Component", "Update Component", "Read Component", "Create Component"]
                },
                {
                    label: 'Defects', icon: <DefectsIcon size={21}/>, to: '/defects',
                    permissions: []
                },
                {
                    label: 'Config Variables', icon: <ConfigurationIcon size={21}/>, to: '/config-variables',
                    permissions: []
                },
                {
                    label: 'Test Configuration', icon: <TestConfigurationIcon size={21}/>, to: '/test-configuration',
                    permissions: []
                },
            ]
        },

    ];

    const menu = allowedMenu(allMenus)

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper flex', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <QueryClientProvider client={queryClient}>

            <div className={wrapperClass} onClick={onWrapperClick} style={{height : "100dvh"}}>

                {isLoggedIn ?
                    <>
                        <Tooltip ref={copyTooltipRef} target=".block-action-copy , .tootlip" position="bottom" content="Copied to clipboard" event="focus" />

                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                            mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                            {/* <Sidebar /> */}
                        </div>

                        <div className="layout-main-container flex-1 overflow-hidden">
                            <div className="layout-main card overflow-y-auto  w-full">
                                <Suspense fallback={<Spinner />} >
                                    <Routes>

                                        <Route path="/" exact={true} element={<Dashboard colorMode={layoutColorMode} location={location} />} >
                                        </Route>
                                        {/* <Route path="/formlayout/*" element={<FormLayoutDemo location={location} />} /> */}
                                        <Route path="/projects" element={<ProjectList />} />
                                        {/* <Route path="/projects/:projectId/folders" element={<Folders />} /> */}
                                        <Route path="/folders" element={<Folders />} />
                                        <Route path="/suite" element={<Suite onToggleMenuClick={onToggleMenuClick} />} />
                                        <Route path="/suite/:id" element={<Suite onToggleMenuClick={onToggleMenuClick} />} />
                                        <Route path="test-plan" element={<TestPlanList />} />
                                        <Route path="test-plan/:id" element={<TestPlanDetails />} />
                                        <Route path="test-plan-logs" element={<TestPlanLogs />} />
                                        {/* <Route path="test-plan-logs/all" element={<TestPlanLogsItems />} /> */}

                                        <Route path="/roles" element={<RoleAdd />} />
                                        <Route path="/users" element={<UsersList />} />
                                        <Route path="/components" element={<ComponentsList />} />
                                        <Route path="/components/add" element={<ComponentAdd />} />
                                        <Route path="/components/:id/edit" element={<ComponentAdd />} />
                                        <Route path="/custom-variables" element={<CustomVariables />} />
                                        <Route path="/ticketing" element={<TicketList />} />
                                        <Route path="/defects" element={<Defects />} />
                                        <Route path="/config-variables" element={<ConfigVariables />} />
                                        <Route path="/test-configuration" element={<TestConfiguration />} />


                                        {/* <Route exact={true} path="/input" element={<InputDemo />} />
                            <Route exact={true} path="/floatlabel" element={<FloatLabelDemo />} />
                            <Route exact={true} path="/invalidstate" element={<InvalidStateDemo />} />
                            <Route exact={true} path="/button" element={<ButtonDemo />} />
                            <Route exact={true} path="/table" element={<TableDemo />} />
                            <Route exact={true} path="/list" element={<ListDemo />} />
                            <Route exact={true} path="/tree" element={<TreeDemo />} />
                            <Route exact={true} path="/panel" element={<PanelDemo />} />
                            <Route exact={true} path="/overlay" element={<OverlayDemo />} />
                            <Route exact={true} path="/media" element={<MediaDemo />} />
                            <Route exact={true} path="/menu" element={<MenuDemo />} />
                            <Route exact={true} path="/messages" element={<MessagesDemo />} />
                            <Route exact={true} path="/blocks" element={<BlocksDemo />} />
                            <Route exact={true} path="/icons" element={<IconsDemo />} />
                            <Route exact={true} path="/file" element={<FileDemo />} />
                            <Route exact={true} path="/chart" element={
                                <ChartDemo colorMode={layoutColorMode} location={location} />
                            } >
                            </Route>
                            <Route exact={true} path="/misc" element={<MiscDemo />} />
                            <Route exact={true} path="/timeline" element={<TimelineDemo />} />
                            <Route exact={true} path="/crud" element={<Crud />} />
                            <Route exact={true} path="/empty" element={<EmptyPage />} />
                            <Route exact={true} path="/documentation" element={<Documentation />} /> */}

                                    </Routes>
                                </Suspense>
                            </div>

                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>



                    </>
                    :
                    <Suspense fallback={<div className='flex justify-content-center align-item-center'>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="6" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>} >
                        <Routes>
                            <Route path="/login" exact={true} element={<Login />} />
                            <Route path="/reset-password"  element={<ResetPassword />} />
                        </Routes>
                    </Suspense>
                }
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
            </div>

        </QueryClientProvider>
    );

}

export default App;
